import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArticleIcon from "@mui/icons-material/Article";
import useMediaQuery from "@mui/material/useMediaQuery";

const App = () => {
  // import images
  const ambiiLogo = process.env.PUBLIC_URL + "/images/ambii-logo.png";
  const hakodateChuoLogo =
    process.env.PUBLIC_URL + "/images/hakodate-chuo-logo.png";
  const obstetricsIcon = process.env.PUBLIC_URL + "/images/obstetrics.svg";
  const pediatricsIcon = process.env.PUBLIC_URL + "/images/pediatrics.svg";

  // check if the user is using a mobile device
  const isMobile = useMediaQuery("(max-width:600px)");

  // redirect to the pediatrics page
  const goToPediatrics = () => {
    window.location.href = "https://patient.ambii.com/form/clinic/D3kA9TvdWMwyybgjmyAL";
  };

  // redirect to the obstetrics page
  const goToObstetrics = () => {
    window.location.href = "https://patient.ambii.com/form/clinic/QghizC2I0lh3VExfUPsD";
  };

  return (
    <Container sx={{ py: 4 }}>
      <Grid container justifyContent={isMobile ? "center" : "left"}>
        <img
          src={hakodateChuoLogo}
          alt="Hakodate Chuo Logo"
          width={isMobile ? "50px" : "60px"}
        />
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: 2 }}>
        <ArticleIcon
          style={{ fontSize: isMobile ? "30px" : "40px", color: "#6B1B42" }}
        />
        <Typography
          sx={{ ml: isMobile ? 1 : 2 }}
          variant={isMobile ? "h5" : "h4"}
          component={"h1"}
          fontWeight="fontWeightMedium"
        >
          函館中央病院
        </Typography>
        <Typography
          sx={{ ml: 1 }}
          variant={isMobile ? "h5" : "h4"}
          component={"h1"}
          fontWeight="fontWeightBold"
        >
          問診票
        </Typography>
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: isMobile ? 3 : 4 }}>
        <Typography
          component={"h2"}
          style={{ color: "gray", fontSize: isMobile ? "15px" : "18px" }}
        >
          診療科を選択してください
        </Typography>
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: 1 }} spacing={3}>
        <Grid item xs={12}>
          <Item>
            <Button
              sx={{
                p: 1,
                borderRadius: 2,
                boxShadow: 4,
                "&:hover": {
                  boxShadow: 7,
                },
              }}
              fullWidth
              variant="contained"
              style={{
                backgroundColor: "white",
                color: "black",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={goToPediatrics}
            >
              <div
                style={{
                  width: "160px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={pediatricsIcon} alt="Pediatrics Icon" height="65px" />
                <Typography
                  sx={{ ml: 1 }}
                  variant={"h5"}
                  fontWeight="fontWeightBold"
                  style={{ color: "#024D5B" }}
                >
                  小児科
                </Typography>
              </div>
            </Button>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Button
              sx={{
                p: 1,
                borderRadius: 2,
                boxShadow: 4,
                "&:hover": {
                  boxShadow: 7,
                },
              }}
              fullWidth
              variant="contained"
              style={{
                backgroundColor: "white",
                color: "black",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={goToObstetrics}
            >
              <div
                style={{
                  width: "160px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={obstetricsIcon} alt="Obstetrics Icon" height="65px" />
                <Typography
                  sx={{ ml: 1 }}
                  variant={"h5"}
                  fontWeight="fontWeightBold"
                  style={{ color: "#024D5B" }}
                >
                  産科
                </Typography>
              </div>
            </Button>
          </Item>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Typography variant={"subtitle1"} sx={{ mr: 1 }}>
          Powered by
        </Typography>
        <img
          src={ambiiLogo}
          alt="Ambii Logo"
          height="18px"
          style={{ paddingBottom: "4px" }}
        />
      </Grid>
    </Container>
  );
};

export default App;
